
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100vh;
    width: 100vw;
    height: 100%;
    min-height: 100%;
    margin: 0 !important;
}

body {
    height: 100%;
    min-height: 100%;
    font-family: 'Muli';
    margin: 0 !important;
    background-color: #c3cad6;
}

/* Remove chrome style */
:focus {
    outline: -webkit-focus-ring-color auto 0;
}
    


.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #374150b2; 
  color: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px); 
}

.preloader > p { 
  position: absolute;
  min-width: 128px;
  min-height: 128px;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 148px;
  text-align: center;
}


/* Datepicker */
.react-datetime-picker input:focus {
    outline: none;
}

.react-datetime-picker__wrapper {
    border: 0px solid gray !important;
}


/* Autocomplete border color focus*/ 

/* .MuiFormLabel-root.Mui-focused {
  color: red !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
} */



.MuiInputBase-input {
  height: 0.9rem !important;
}



#menu_sidebar {
  background-color: #2e354c;
}


#main-container {
  background-color: #c3cad6;
  max-width: 1265px;
}

#menuleft-container-misdatos {
  background-color: #21272f;
}

.bg-temuco-green {
  background-color: #2cbb86;
}


.bg-temuco-green:hover {
  background-color: #17a772;
  
}

.input-temuco {
  border: 2px solid #e1eef7;
}

.input-temuco:focus {
  border: 2px solid #b2e0dc;
}

.select-temuco:focus {
  border: 2px solid #b9e4e0;
}


/* 
.bg-temuco-menu-active {
  background-color: #d4d9e0
}

.bg-temuco-menu:hover {
  background-color: #c8cdd4
}

.text-gray-450 {
  color: #919daa;
} */


select:disabled{
  background-color: #eee;
  color: #aaa;
}

select:enabled{
  border: 1px solid #8bd1ce;
  /* background-color: #ffffff; */
}

select-temuco-2:enabled{
  border: 1px solid #b4d8d6 !important;
 background-color: #ffffff; 
}

.select-temuco-2:focus {
  border: 1px solid #69c2b9;
}




.bg-td-reporte {
  
}


.table-reporte tr:nth-child(odd){ 
  background: #FFFFFF;
}
/*  Define the background color for all the EVEN background rows  */
.table-reporte tr:nth-child(even){
  background: #f3f6f7;
}


.bg-table-reporte {
  background-color: #cbeaec !important;
  color: #1e4963;
}




#reporte_mensual_trimestral_conainer {
  max-width: 1200px;
}

